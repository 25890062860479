import React, { useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TableSortLabel from '@mui/material/TableSortLabel';
  import ReceiptIcon from '@mui/icons-material/Receipt';

import componentStyles from "assets/theme/views/admin/tables.js";
import { Checkbox, Chip, FormControlLabel, FormGroup, IconButton, InputAdornment, Radio, RadioGroup, TablePagination, TextField, Tooltip } from "@mui/material";
import moment from "moment";
import ThreeDotsMenu from "../../components/ThreeDotMenu/ThreeDotMenu";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LoadingIcon } from "components/Icons/Loading";

import { LocalizationProvider, DatePicker, CalendarIcon } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Search } from "@mui/icons-material";

//import moment from "moment";

const useStyles = makeStyles(componentStyles);

const OrdersTable = (props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);


  let timeout = 0;
  const { title, rows, page, total, totalQuantityIn, pagedata, setSearchText, setCustomerSearchText, setFileSearchText, order, orderBy, orderData, orderByData, columns, editAction, actions, setOffset, offset, generateChallanAction, loading, setCuttingTableFilter, cuttingTableFilter, setOrderFilter, orderFilter, isOrderFlow, handleRowClick, loadingRows, history, WorkCenterId } = props;
  const headCells = columns;
  const classes = useStyles();

  const [productionDate, setProductionDate] = useState(null)

  const handleViewClick = (row) => {
    let action = actions?.find(rs => { return rs?.label === 'View' })
    action.action(row)
  };

  //Handle Page changes
  const handleChangePage = (event, newPage) => {
    pagedata(newPage + 1);
  };

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };


  //Handle Page changes
  const handleChangePageRows = (event) => {
    setOffset(parseInt(event.target.value, 10))
    pagedata(1);
  };

  //Search data
  const handleSearchInput = (e) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      switch (e.target.name) {
        case 'CustomerName':
          setCustomerSearchText(e.target.value)
          break;
        case 'FileName':
          setFileSearchText(e.target.value)
          break;
        case 'CommonSearch':
          setSearchText(e.target.value)
          break;
      }
      pagedata(1);
    }, 400);
  }

  /*------------- Table Headers Start ------------*/
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    orderData(isAsc ? 'desc' : 'asc');
    orderByData(property);
  };


  function EnhancedTableHead(props) {
    const { classes, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {title === 'Delivery' &&

            <TableCell padding="checkbox"
              key={300}
              align={'center'}
              classes={{
                root:
                  classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >

              Select
            </TableCell>
          }

          <TableCell
            key={200}
            align={'center'}
            padding={'none'}
            classes={{
              root:
                classes.tableCellRoot + " " + classes.tableCellRootHead,
            }}
          >
            view
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={'none'}
              sortDirection={orderBy === headCell.id ? order : false}
              classes={{
                root:
                  classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell
            key={100}
            align={'center'}
            padding={'none'}
            classes={{
              root:
                classes.tableCellRoot + " " + classes.tableCellRootHead,
            }}
          >
            Action
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  /*------------- Table Headers End ------------*/

  /*------------- Table Content Start ------------*/
  const [showGCToolTip, setShowGCToolTip] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reloadChkBox, setReloadChkBox] = useState(false);
  /*------------- Table Content End ------------*/

  //const isSelected = (selFlowStepId) => selectedRows.filter(item => item.FlowStepId === selFlowStepId).length > 0 ? true : false;

  const handleCheckBoxes = (evt, rData) => {
    setReloadChkBox(!reloadChkBox);
    if (evt.target.checked) {
      let selRows = selectedRows;
      selRows?.push(rData);
      setSelectedRows(selRows);
      setShowGCToolTip(true);
    }
    else {
      let selRows = selectedRows;
      selRows = selRows.filter(x => { return x.FlowStepId !== rData?.FlowStepId })
      if (selRows?.length === 0) {
        setShowGCToolTip(false);
      }
      setSelectedRows(selRows)
    }
  }

  const generateChallanHandle = () => {
    //setSelectedGCOrders(selectedRows);
    setShowGCToolTip(false);
    generateChallanAction(selectedRows);
  }

  const handleChangeOrderFilter = (event) => {
    const selectedValue = event.target.value;

    setOrderFilter(prevState => {
      if (prevState[selectedValue] === 1) {
        return {
          ...prevState,
          [selectedValue]: null
        };
      } else {
        return {
          TagInterlock: null,
          TagZigZag: null,
          BindingsDieCut: null,
          BindingsFoil: null,
          BindingsTagPrinted: null,
          [selectedValue]: 1
        };
      }
    });
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        subheader={
          <>
            <Grid
              container spacing={2}
            >
              <Grid item xs={12} xl={2} className="pr-0">
                <Box
                  component={Typography}
                  variant="h3"
                  sx={{ mt: 0.5 }}
                >
                  <span>{title}</span>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} xl={2.5} className="pr-0">
                <TextField
                  size='small'
                  id="name"
                  fullWidth
                  name="CommonSearch"
                  autoComplete="name"
                  placeholder={"Search"}
                  onChange={(e) => { handleSearchInput(e) }}
                  sx={{
                    '& .MuiOutlinedInput-input:not(:last-child)': {
                      height: 35,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className="text-black" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} xl={2.5} className="pr-0">
                <TextField
                  size='small'
                  id="name"
                  fullWidth
                  name="CustomerName"
                  autoComplete="name"
                  placeholder={"Customer Name"}
                  onChange={(e) => { handleSearchInput(e) }}
                  sx={{
                    '& .MuiOutlinedInput-input:not(:last-child)': {
                      height: 35,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className="text-black" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={title === 'Delivery' ? 5 : 6} xl={2.5} className="pr-0">
                <TextField
                  size='small'
                  id="name"
                  fullWidth
                  name="FileName"
                  autoComplete="name"
                  placeholder={"File Name"}
                  onChange={(e) => { handleSearchInput(e) }}
                  sx={{
                    '& .MuiOutlinedInput-input:not(:last-child)': {
                      height: 35,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className="text-black" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={title === 'Delivery' ? 5 : 6} xl={title === 'Delivery' ? 1.5 : 2.5} display="flex" justifyContent="flex-end">
                <div className="bg-white custom-datepicker">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      open={open}
                      onClose={() => setOpen(false)}
                      value={productionDate ? new Date(productionDate) : null}
                      onChange={(date) => {
                        if (moment(date, "DD/MM/YYYY", true).isValid()) {
                          const formattedDate = moment(date).format("YYYY-MM-DD");
                          const selectedYear = moment(date).format("YYYY");
                          const selectedMonth = moment(date).format("MM");
                          const selectedDay = moment(date).format("DD");
                          setProductionDate(formattedDate);
                          history.push(
                            `/admin/report/productions-report-daily-detail/${selectedYear}/${selectedMonth}/${WorkCenterId}/${selectedDay}/${WorkCenterId || 0}`
                          );
                        }
                      }}
                      slots={{ textField: () => null }}
                      slotProps={{
                        popper: {
                          anchorEl: anchorEl,
                          placement: 'bottom-start',
                        },
                      }}
                    />
                    <Tooltip title="View Production Report">
                      <Box
                        onClick={handleIconClick}
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          padding: '8px',
                          cursor: 'pointer',
                          height: '40px',
                          width: '40px',
                        }}
                      >
                        <IconButton sx={{ padding: 0, ml: 1 }}>
                          <CalendarIcon />
                        </IconButton>
                      </Box>
                    </Tooltip>

                  </LocalizationProvider>

                </div>
              </Grid>
              {title === 'Delivery' &&
                <Grid item xs={12} sm={2} lg={1} className="pr-0">
                  <Box
                    aria-controls="simple-menu-1"
                    aria-haspopup="true"
                    size="large"
                    component={Button}
                    className="w-full bg-blue-500 rounded-full my-1 h-9"
                    onClick={() => { generateChallanHandle() }}
                  >
                    <Tooltip
                      onMouseEnter={() => { console.log('enter'); setShowGCToolTip(true) }}
                      open={showGCToolTip}
                      classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.customArrow
                      }}
                      title="Generate Challan" placement="top" arrow>
                      <ReceiptIcon className="w-6 h-auto -mt-2  text-white" />
                    </Tooltip>

                  </Box>
                </Grid>
              }


            </Grid>
            {isOrderFlow &&
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: { xs: 'center', sm: 'flex-end' },
                  alignItems: 'center',
                  gap: '16px'
                }}
              >
                <FormGroup>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="Type"
                    value={Object.keys(orderFilter).find(key => orderFilter[key] === 1) || ''}
                  >
                    <FormControlLabel
                      value="TagInterlock"
                      control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                      label="Interlock"
                    />
                    <FormControlLabel
                      value="TagZigZag"
                      control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                      label="ZigZag"
                    />
                    <FormControlLabel
                      value="BindingsDieCut"
                      control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                      label="Die Cut"
                    />
                    <FormControlLabel
                      value="BindingsFoil"
                      control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                      label="Foil"
                    />
                    <FormControlLabel
                      value="BindingsTagPrinted"
                      control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                      label="Tag Printed"
                    />
                  </RadioGroup>
                </FormGroup>
              </Box>}
          </>
        }
        classes={{ root: classes.cardHeaderRoot }}
      ></CardHeader>
      <TableContainer sx={{ mt: isOrderFlow ? -8 : -3 }}>
        <Box
          component={Table}
          alignItems="center"

        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
          />

          {/* <EnhancedTableRows
              classes={classes}
              rows={rows}
              page={page}
              total={total}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            /> */}

          <TableBody className="relative">
            {rows?.length > 0 && rows?.map((rowData, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              const checkForIsRepeat = rowData?.IsRepeat === 1 && (rowData?.WorkCenterName === 'Photo Making' || rowData?.WorkCenterName === 'Tagging');
              let actionList = actions;

              if (checkForIsRepeat) {
                actionList = actionList.filter(ac => {
                  return ac.label !== 'Start'
                })
              }

              return (

                // // <TableRow key={index} onClick={(e) => {handleViewClick(rowData)}} className="cursor-pointer">
                <TableRow onDoubleClick={() => handleRowClick && handleRowClick(rowData)} key={index} className={`cursor-pointer ${rowData?.IsOnHold === 1 && rowData?.FlowStepHoldReason !== '' ? 'text-red-500' : ''} ${rowData?.IsOnHold === 1 && rowData?.FlowStepHoldReason === '' ? 'bg-red-300 text-gray-200 animate-pulse' : rowData?.StartDate ? 'bg-green-100' : checkForIsRepeat ? 'bg-gray-100' : 'bg-white'} `}>
                  {/* <TableRow key={index} className={`cursor-pointer ${rowData?.IsOnHold === 1 && rowData?.FlowStepHoldReason !== '' ? 'text-red-500' : ''} ${rowData?.IsOnHold === 1 && rowData?.FlowStepHoldReason === '' ? 'bg-red-300 text-gray-200 animate-pulse' : rowData?.StartDate ? 'bg-green-100' : checkForIsRepeat ? 'bg-gray-100' : 'bg-white'} `}> */}
                  {loadingRows[rowData.FlowStepId] ? (
                    <TableCell colSpan={columns.length + 2} align="center">
                      <LoadingIcon />
                    </TableCell>
                  ) : (
                    <>
                      {title === 'Delivery' && (
                        <TableCell padding="checkbox">
                          {rowData?.StartDate &&
                            <Checkbox
                              color="primary"
                              onChange={(e) => { handleCheckBoxes(e, rowData) }}
                              checked={selectedRows.filter(item => Number(item.FlowStepId) === Number(rowData.FlowStepId)).length > 0 ? true : false}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          }
                        </TableCell>)
                      }

                      <TableCell
                        classes={{ root: classes.tableCellRoot }}
                        align="left"
                      >

                        <Box
                          aria-controls="simple-menu-1"
                          aria-haspopup="true"
                          onClick={(e) => { handleViewClick(rowData) }}
                          size="small"
                          component={Button}
                          className="p-0"
                          width="2rem!important"
                          height="2rem!important"
                          minWidth="2rem!important"
                          minHeight="2rem!important"
                        >
                          {rowData?.IsOnHold === 1 && rowData?.FlowStepHoldReason === '' ?
                            <Tooltip title="Add Hold Reason" placement="top" arrow>
                              <VisibilityIcon fontSize="small" style={{ color: "#424242" }} />
                            </Tooltip>
                            :
                            <VisibilityIcon fontSize="small" style={{ color: "#424242" }} />
                          }
                        </Box>
                      </TableCell>
                      {headCells.map((headCell, hdIndex) => (
                        <TableCell
                          key={hdIndex}
                          classes={{
                            root:
                              classes.tableCellRoot +
                              " " +
                              classes.tableCellRootBodyHead,
                          }}
                          component="th"
                          className={`${rowData?.IsOnHold === 1 && rowData?.FlowStepHoldReason !== '' ? 'text-red-500' : checkForIsRepeat ? 'text-gray-400' : ''} `}
                          variant="head"
                          scope="row"
                        >
                          {headCell.dataType === "component" && headCell.Component(rowData)}
                          {headCell.dataType === 'string' && rowData[headCell.id]}
                          {/* {headCell.dataType === 'date' && moment(rowData[headCell.id]).format('Do MMMM YYYY')} */}
                          {headCell.dataType === 'date' && moment(rowData[headCell.id]).format('D-MM-YYYY')}


                        </TableCell>
                      ))
                      }
                      <TableCell
                        classes={{ root: classes.tableCellRoot }}
                        align="right"
                      // className={`${rowData?.StartDate ? 'bg-green-100' : 'bg-white'} ${rowData?.IsOnHold === 1 ? 'text-red-100' : ''} `}
                      >
                        <ThreeDotsMenu data={rowData} actions={actionList} index={index} />
                      </TableCell>
                    </>
                  )}
                </TableRow>

              )
            })
            }
            {rows?.length === 0 && !loading &&
              <TableRow key={0}>
                <TableCell colSpan={columns?.length + 2} classes={{ root: classes.tableCellRoot }} align="center" className='font-bold'>
                  Record Not Found
                </TableCell>
              </TableRow>
            }
            {loading &&
              <TableRow key={0}>
                <TableCell colSpan={columns?.length + 2} classes={{ root: classes.tableCellRoot }} align="center" className='font-bold'>
                  <LoadingIcon overlay={rows?.length > 0} />
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Box>
      </TableContainer>
      <Box
        classes={{ root: classes.cardActionsRoot }}
        component={CardActions}
        justifyContent="space-between"
      >
        <div>
          <Chip className="font-bold bg-blue-600 mr-2" color="primary" label={`Total Quantity : ${totalQuantityIn}`} />
          <Chip className="font-bold bg-blue-600" color="primary" label={`Total Records : ${total}`} />
          {(title === "Cutting" || title === "Interlock" || title === "Zigzag") && <>
            <Typography variant="caption" className="pl-2 pr-2 text-sm font-bold">Table:</Typography>
            {[1, 2, 3, 4, 5, 6, 7].map((value, index) => {
              return <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    color="primary"
                    checked={cuttingTableFilter?.indexOf(value) >= 0}
                    onChange={(e) => {
                      //let cuttingTables = [...cuttingTableFilter];
                      if (e.target.checked) {
                        //cuttingTables.push(value);
                        setCuttingTableFilter([value]);
                      } else {
                        //let cutIndx = cuttingTables?.indexOf(value);
                        //cuttingTables.splice(cutIndx, 1);
                        setCuttingTableFilter([]);
                      }

                      //setCuttingTableFilter(cuttingTables);
                    }}
                  />
                }
                label={value}
                labelPlacement="end"
              />
            })}
          </>}
        </div>
        <div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={offset}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePageRows}
          />
        </div>
      </Box>
    </Card >

  );
};

export default OrdersTable;