import React from 'react';
import { styled } from '@mui/system';
import { Paper, Typography } from '@mui/material';

const Square = styled(Paper)(({ theme }) => ({
  width: 25,
  height: 25,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const CharacterBox = ({ character, classNames }) => {
  return (
    <Square className={classNames}>
      <Typography className='text-white' variant="body1">{character}</Typography>
    </Square>
  );
};

export default CharacterBox;