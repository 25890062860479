import React from "react";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button } from "@mui/material";
import DeleteConfirmDialog from "components/dialoges/DeleteConfirmDialog";
import { showVerificationDialog } from "components/dialoges/VerifiyOTPDialog";

const ThreeDotsMenu = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = React.useState(false);
  const [confirmData, setConfirmData] = React.useState(null);
  const { data, actions, index } = props;

  function handleMenuClick(action, rowData) {
    if ((action?.label === 'Edit' || action?.label === 'Scrap' || action?.label === 'Delete') && !action?.notAuthenticated ) {
      //TEMP HIDE VERIFICATION DIALOG
      showVerificationDialog('Verify Authorization', true, () => {
      }, async () => {
        action?.action(rowData)
        handleClose();
      })
    }
    else {
      action?.action(rowData)
      handleClose();
    }
  }

  function handleConfirmClick(action, rowData) {
    action?.action(rowData)
    handleClose();
  }

  function handleShowConfirmDialog(action, rowData) {
    if (action?.label === 'Delete' && !action?.notAuthenticated) {
      //VERIFICATION DIALOG
      showVerificationDialog('Verify Authorization', true, () => {
      }, async () => {
        setShowDeleteConfirmDialog(true)
        setConfirmData({ action: action, rowData: rowData })
      })
    }
    else {
      setShowDeleteConfirmDialog(true)
      setConfirmData({ action: action, rowData: rowData })
    }
  }

  // //TEMP HIDE VERIFICATION DIALOG
  // showVerificationDialog('Verify Authorization',true, () => {
  // }, async () => {
  //     handleSubmitResponse(res);
  // })

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <div key={index}>
      <IconButton
        aria-label="more-2"
        aria-controls="long-menu-2"
        aria-haspopup="true"
        onClick={(e) => { e.stopPropagation(); handleClick(e) }}
      ///onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      {anchorEl &&


        <Menu
          id={`card-actions-menu-${index}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}

        >
          {actions.map((action, aIndex) => {

            let showFinishLabel = true;

            if(action?.label === 'Finish'){
              if(data?.StartDate === null){
                const checkForIsRepeat = data?.IsRepeat === 1 && (data?.WorkCenterName === 'Photo Making' || data?.WorkCenterName === 'Tagging');
                if(!checkForIsRepeat){
                  showFinishLabel = false;  
                }
                //showFinishLabel = false;
              }
              // else{
              //   const checkForIsRepeat = data?.IsRepeat === 1 && (data?.WorkCenterName === 'Photo Making' || data?.WorkCenterName === 'Tagging');
              //   if(checkForIsRepeat === true){
              //     showFinishLabel = false;
              //   }
              // }
            }
            
            //&& action?.IsRepeat !== true
            const disabled = action.disabled ? action?.disabled(data) : false;
            return (
            (action?.label === 'Start' && data?.StartDate !== null) ||
              (!showFinishLabel) ||
              (action?.label === 'Hold Reason' && !data?.IsOnHold)
              ? null :
              <MenuItem disabled={disabled} className={`${action?.label === 'Hold Reason' ? "bg-red-500 text-white" : ""} flex flex-wrap items-center mt-1 ${actions?.length - 1 !== aIndex ? 'border-b border-solid border-gray-300' : ''}`} key={aIndex}
                onClick={() => action?.isConfirmAction ? handleShowConfirmDialog(action, data) : handleMenuClick(action, data)} >
                {typeof action?.icon === "object" || typeof action?.icon === "function" ? (

                  <Box
                    aria-controls="simple-menu-1"
                    aria-haspopup="true"
                    size="small"
                    component={Button}
                    width="1.5rem!important"
                    height="1.5rem!important"
                    minWidth="1.5rem!important"
                    minHeight="1.5rem!important"
                    className={`px-0 py-0 bg-gray-100 mr-2 -mt-1`}
                  >
                    <Box
                      component={action?.icon}
                      width="1rem!important"
                      height="1rem!important"
                      position="relative"
                      className={`text-${action.iconColor}`}
                    />
                  </Box>
                  // <Box
                  //   component={action?.icon}
                  //   width="1.25rem!important"
                  //   height="1.25rem!important"
                  //   className={`text-${action.iconColor}`}
                  // />
                ) : null}
                {action?.label}
              </MenuItem>



          )})}
        </Menu>
      }
      {showDeleteConfirmDialog &&
        <DeleteConfirmDialog onClose={setShowDeleteConfirmDialog} open={showDeleteConfirmDialog} handleConfirm={handleConfirmClick} confirmData={confirmData} setConfirmData={setConfirmData} />
      }
    </div>
  )
}

export default ThreeDotsMenu;