import React from "react";
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, MenuItem } from "@mui/material";

export function OrderFile(props) {

    return (
        <>
        {/* Party Fabric Information Start */}
        <div className="border-2 border-yellow-300 rounded-md mb-6">
                    <div className="bg-yellow-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Party Fabric</h3>
                    </div>
                    <div className="p-2">
                    <Grid container>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormLabel>Full</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBFull"
                            value={props?.orderModel?.PBFull}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={1}>
                        <FormGroup>
                          <FormLabel>F Gaze</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBFGaze"
                            value={props?.orderModel?.PBFGaze}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={1}>
                        <FormGroup>
                          <FormLabel>T Gaze</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBTGaze"
                            value={props?.orderModel?.PBTGaze}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormLabel>Full Meter</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBFullMtr"
                            value={props?.orderModel?.PBFullMtr}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormLabel>Half</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBHalf"
                            value={props?.orderModel?.PBHalf}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormLabel>Half Meter</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBHalfMtr"
                            value={props?.orderModel?.PBHalfMtr}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>
                      <Grid container>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Total Fabric</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBTotal"
                            value={props?.orderModel?.PBTotal}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Total Meter</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBTotalMtr"
                            value={props?.orderModel?.PBTotalMtr}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} lg={12}>
                        <FormGroup>
                          <FormLabel>Remarks</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBRemarks"
                            value={props?.orderModel?.PBRemarks}
                            multiline
                            minRows={4}
                            maxRows={6}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>
                    </div>  
                  </div>
                  {/* Party Fabric Information End */}

                  
                  {/* Cutting Information Start */}
                  <div className="border-2 border-blue-300 rounded-md mb-6">

                    <div className="bg-blue-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Cutting</h3>
                    </div>

                    <div className="p-2">
                    <Grid container>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Patta</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="CuttingPatta"
                            value={props?.orderModel?.CuttingPatta}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Tukda</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="CuttingTukda"
                            value={props?.orderModel?.CuttingTukda}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Table</FormLabel>
                          <Select value={props?.orderModel?.CuttingTable} name="CuttingTable" onChange={e => props?.handleSelectChange('CuttingTable', e.target.value)}>
                            <MenuItem value={null}>Select</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                          </Select>
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid container>
                    <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TagZigZag}
                                onChange={props?.handleCheckBoxes}
                                name="TagZigZag"
                                color="primary"
                              />
                            }
                            label="ZigZag"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TagInterlock}
                                onChange={props?.handleCheckBoxes}
                                name="TagInterlock"
                                color="primary"
                              />
                            }
                            label="Interlock"
                          />
                        </FormGroup>
                      </Grid>
                      </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={12}>
                        <FormGroup>
                          <FormLabel>Remarks</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="CuttingRemarks"
                            value={props?.orderModel?.CuttingRemarks}
                            multiline
                            minRows={4}
                            maxRows={6}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>
                    </div>
                  </div>
                  {/* Cutting Information End */}

                  {/* Binding Information Start */}
                  <div className="border-2 border-red-300 rounded-md mb-6">

                    <div className="bg-red-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Binding</h3>
                    </div>

                    <div className="p-2">
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Fabric Color</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="BindingsFabricColor"
                            value={props?.orderModel?.BindingsFabricColor}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.Bindings15}
                                onChange={props?.handleCheckBoxes}
                                name="Bindings15"
                                color="primary"
                              />
                            }
                            label="1.5"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.Bindings11}
                                onChange={props?.handleCheckBoxes}
                                name="Bindings11"
                                color="primary"
                              />
                            }
                            label="11"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.BindingsFull}
                                onChange={props?.handleCheckBoxes}
                                name="BindingsFull"
                                color="primary"
                              />
                            }
                            label="Full"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Kaan</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="BindingsKaan"
                            value={props?.orderModel?.BindingsKaan}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Patti</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="BindingsPatti"
                            value={props?.orderModel?.BindingsPatti}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Dori</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="BindingsDori"
                            value={props?.orderModel?.BindingsDori}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Corner</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="BindingsCorner"
                            value={props?.orderModel?.BindingsCorner}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid container>
                    <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.BindingsDieCut}
                                onChange={props?.handleCheckBoxes}
                                name="BindingsDieCut"
                                color="primary"
                              />
                            }
                            label="Die Cut"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.BindingsFoil}
                                onChange={props?.handleCheckBoxes}
                                name="BindingsFoil"
                                color="primary"
                              />
                            }
                            label="Foil"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.BindingsTagPrinted}
                                onChange={props?.handleCheckBoxes}
                                name="BindingsTagPrinted"
                                color="primary"
                              />
                            }
                            label="Tag Printed"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.BindingsMDF}
                                onChange={props?.handleCheckBoxes}
                                name="BindingsMDF"
                                color="primary"
                              />
                            }
                            label="MDF"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.BindingsCappaBoard}
                                onChange={props?.handleCheckBoxes}
                                name="BindingsCappaBoard"
                                color="primary"
                              />
                            }
                            label="Cappa Board"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid container>
                    <Grid item xs={12} lg={12}>
                      <FormGroup>
                        <FormLabel>Remarks</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="BindingsRemarks"
                          value={props?.orderModel?.BindingsRemarks}
                          multiline
                          minRows={4}
                          maxRows={6}
                          onChange={e => props?.handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  </div>


                  </div>
                  {/* Binding Information End */}

                  {/* Tagging Information Start */}
                  <div className="border-2 border-green-300 rounded-md mb-6">

                    <div className="bg-green-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Tagging</h3>
                    </div>

                    <div className="p-2">
                    <Grid container>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TagLogo}
                                onChange={props?.handleCheckBoxes}
                                name="TagLogo"
                                color="primary"
                              />
                            }
                            label="Logo"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TagCollection}
                                onChange={props?.handleCheckBoxes}
                                name="TagCollection"
                                color="primary"
                              />
                            }
                            label="Collection"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TagDesign}
                                onChange={props?.handleCheckBoxes}
                                name="TagDesign"
                                color="primary"
                              />
                            }
                            label="Design"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TagShed}
                                onChange={props?.handleCheckBoxes}
                                name="TagShed"
                                color="primary"
                              />
                            }
                            label="Shed"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TagInstruction}
                                onChange={props?.handleCheckBoxes}
                                name="TagInstruction"
                                color="primary"
                              />
                            }
                            label="Instruction"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TagSymbol}
                                onChange={props?.handleCheckBoxes}
                                name="TagSymbol"
                                color="primary"
                              />
                            }
                            label="Symbol"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TagSrNo}
                                onChange={props?.handleCheckBoxes}
                                name="TagSrNo"
                                color="primary"
                              />
                            }
                            label="Sr No."
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} lg={1.5}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TagWidth}
                                onChange={props?.handleCheckBoxes}
                                name="TagWidth"
                                color="primary"
                              />
                            }
                            label="Width"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2.5}>
                        <FormGroup>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="TagWidthValue"
                            value={props?.orderModel?.TagWidthValue}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                         <Grid item xs={12} lg={1.5}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TaggingGSM}
                                onChange={props?.handleCheckBoxes}
                                name="TaggingGSM"
                                color="primary"
                              />
                            }
                            label="Weight"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2.5}>
                        <FormGroup>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="TaggingGSMValue"
                            value={props?.orderModel?.TaggingGSMValue}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={1.5}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TaggingRubs}
                                onChange={props?.handleCheckBoxes}
                                name="TaggingRubs"
                                color="primary"
                              />
                            }
                            label="Rubs"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2.5}>
                        <FormGroup>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="TaggingRubsValue"
                            value={props?.orderModel?.TaggingRubsValue}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                    <Grid item xs={12} lg={12}>
                      <FormGroup>
                        <FormLabel>Remarks</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="TagRemarks"
                          value={props?.orderModel?.TagRemarks}
                          multiline
                          minRows={4}
                          maxRows={6}
                          onChange={e => props?.handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  </div>
                  </div>
                  {/* Tagging Information End */}

                  {/* Photos Information Start */}
                  <div className="border-2 border-indigo-300 rounded-md mb-6">

                    <div className="bg-indigo-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Photo Making</h3>
                    </div>

                    <div className="p-2">
                    <Grid container>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormLabel>Photo Size</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PhotosSize"
                            value={props?.orderModel?.PhotosSize}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={1}  className="pt-8">
                        <Typography className="font-semibold"> Page </Typography>
                      </Grid>
                      <Grid item xs={12} lg={2}  className="pt-6">
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.PhotosPageArry?.includes(1)}
                                onChange={props?.handleMultiCheckBoxes}
                                value={1}
                                name="PhotosPage"
                                color="primary"
                              />
                            }
                            label="1"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2} className="pt-6">
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                              checked={props?.orderModel?.PhotosPageArry?.includes(2)}
                                onChange={props?.handleMultiCheckBoxes}
                                value={2}
                                name="PhotosPage"
                                color="primary"
                              />
                            }
                            label="2"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2} className="pt-6">
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                               checked={props?.orderModel?.PhotosPageArry?.includes(3)}
                                onChange={props?.handleMultiCheckBoxes}
                                value={3}
                                name="PhotosPage"
                                color="primary"
                              />
                            }
                            label="3"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2} className="pt-6">
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.PhotosPageArry?.includes(4)}
                                onChange={props?.handleMultiCheckBoxes}
                                value={4}
                                name="PhotosPage"
                                color="primary"
                              />
                            }
                            label="4"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                            <FormControlLabel
                              className="ml-2"
                              control={
                                <Checkbox
                                  checked={props?.orderModel?.PhotosLamination}
                                  onChange={props?.handleCheckBoxes}
                                  name="PhotosLamination"
                                  color="primary"
                                />
                              }
                              label="Lamination"
                            />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                              <FormControlLabel
                                className="ml-2"
                                control={
                                  <Checkbox
                                    checked={props?.orderModel?.PhotosColdPage}
                                    onChange={props?.handleCheckBoxes}
                                    name="PhotosColdPage"
                                    color="primary"
                                  />
                                }
                                label="Cold Page"
                              />
                          </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                              <FormControlLabel
                                className="ml-2"
                                control={
                                  <Checkbox
                                    checked={props?.orderModel?.PhotosMatt}
                                    onChange={props?.handleCheckBoxes}
                                    name="PhotosMatt"
                                    color="primary"
                                  />
                                }
                                label="Matt"
                              />
                          </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                              <FormControlLabel
                                className="ml-2"
                                control={
                                  <Checkbox
                                    checked={props?.orderModel?.PhotosGlossy}
                                    onChange={props?.handleCheckBoxes}
                                    name="PhotosGlossy"
                                    color="primary"
                                  />
                                }
                                label="Glossy"
                              />
                          </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} lg={12}>
                        <FormGroup>
                          <FormLabel>Remarks</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PhotosRemarks"
                            value={props?.orderModel?.PhotosRemarks}
                            multiline
                            minRows={4}
                            maxRows={6}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                    </Grid>
                  </Grid>
                  </div>


                  </div>
                  {/* Photos Information End */}

                  {/* Screen Printing Information Start */}
                  <div className="border-2 border-purple-300 rounded-md mb-6">


                    <div className="bg-purple-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Screen Printing</h3>
                    </div>


                    <div className="p-2">
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Top</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="ScreenTop"
                            value={props?.orderModel?.ScreenTop}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Bottom</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="ScreenBottom"
                            value={props?.orderModel?.ScreenBottom}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Patti</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="ScreenPatti"
                            value={props?.orderModel?.ScreenPatti}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <FormGroup>
                          <FormLabel>Printed</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="ScreenPrinted"
                            value={props?.orderModel?.ScreenPrinted}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={1} className="pt-6">
                        <FormGroup>
                              <FormControlLabel
                                className="ml-2"
                                control={
                                  <Checkbox
                                    checked={props?.orderModel?.ScreenPrintedBit}
                                    onChange={props?.handleCheckBoxes}
                                    name="ScreenPrintedBit"
                                    color="primary"
                                  />
                                }
                                label=""
                              />
                          </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                    <Grid item xs={12} lg={12}>
                      <FormGroup>
                        <FormLabel>Remarks</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="ScreenRemarks"
                          value={props?.orderModel?.ScreenRemarks}
                          multiline
                          minRows={4}
                          maxRows={6}
                          onChange={e => props?.handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>

                  </div>


                  </div>
                  {/* Screen Printing Information End */}

                  {/* Fitting Information Start */}
                  <div className="border-2 border-pink-300 rounded-md mb-6">
                    

                    <div className="bg-pink-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Fittings</h3>
                    </div>


                    <div className="p-2">

                    <Grid container>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Step Gap</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="FittingStepGap"
                            value={props?.orderModel?.FittingStepGap}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}  className="pt-6">
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.FittingNumberArry?.includes(1)}
                                onChange={props?.handleMultiCheckBoxes}
                                value={1}
                                name="FittingNumber"
                                color="primary"
                              />
                            }
                            label="1"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2} className="pt-6">
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.FittingNumberArry?.includes(2)}
                                onChange={props?.handleMultiCheckBoxes}
                                value={2}
                                name="FittingNumber"
                                color="primary"
                              />
                            }
                            label="2"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2} className="pt-6">
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.FittingNumberArry?.includes(3)}
                                onChange={props?.handleMultiCheckBoxes}
                                value={3}
                                name="FittingNumber"
                                color="primary"
                              />
                            }
                            label="3"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2} className="pt-6">
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.FittingNumberArry?.includes(4)}
                                onChange={props?.handleMultiCheckBoxes}
                                value={4}
                                name="FittingNumber"
                                color="primary"
                              />
                            }
                            label="4"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} lg={12}>
                        <FormGroup>
                          <FormLabel>Remarks</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="FittingRemarks"
                            value={props?.orderModel?.FittingRemarks}
                            multiline
                            minRows={4}
                            maxRows={6}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                    </Grid>
                  </Grid>
                  </div>


                  </div>
                  {/* Fitting Information End */}
        </>
    );
};