import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import { Tooltip, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';
import ViewDataDialog from "components/dialoges/ViewDataDialog";
import { currentUser } from "services/user-service";
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from "@mui/icons-material"
import TaskTable from "../../../components/Tables/TaskTable";
import moment from "moment";
import MyTaskDetailViewDialog from "components/dialoges/MyTaskDetailViewDialog";

const useStyles = makeStyles(componentStyles);

const TaskList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [taskList, setTaskList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [searchStatus, setSearchStatus] = useState('Assigned');

  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [viewDialogData, setViewDialogData] = useState({
    labels: [],
    data: {}
  });

  const [viewDetailDialog, setViewDetailDialog] = useState({
    isVisible: false,
    data: null,
    extraData : null
  });

  const dateDisplayComponent = (date) => {
    return (
      <Box component="p" sx={{ maxWidth: 50 }}>
        {date ? moment(date).format("D-MM-YYYY") : "-"}
      </Box>
    )

  }

  const columns = [
    {
      id: 'Title',
      numeric: false,
      disablePadding: true,
      label: 'Title',
      Component: ({ row }) => {
        return (
          <Tooltip title={row?.Title}>
            <Box component="p" sx={{ textOverflow: "ellipsis", maxWidth: 180, overflow: 'hidden' }}>
              {row?.Title}
            </Box>
          </Tooltip>
        )
      },
    },
    {
      id: 'Description',
      numeric: false,
      disablePadding: true,
      label: 'Description',
      Component: ({ row }) => {
        return (
          <Tooltip title={row?.Description}>
            <Box component="p" sx={{ textOverflow: "ellipsis", maxWidth: 350, overflow: 'hidden' }}>
              {row?.Description}
            </Box>
          </Tooltip>
        )
      },
    },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status', dataType: 'string', isSelectEditable: true },
    { id: 'Name', numeric: false, disablePadding: true, label: 'Assigned To', dataType: 'string' },
    {
      id: 'ReadAt', numeric: false, disablePadding: true, label: 'Read At',
      Component: ({ row }) => {
        return (
          dateDisplayComponent(row?.ReadAt)
        )
      },
    },
    {
      id: 'DueDate', numeric: false, disablePadding: true, label: 'Due Date',
      Component: ({ row }) => {
        return (
          dateDisplayComponent(row?.DueDate)
        )
      },
    },
    {
      id: 'FinishedAt', numeric: false, disablePadding: true, label: 'Finished At',
      Component: ({ row }) => {
        return (
          dateDisplayComponent(row?.FinishedAt)
        )
      },
    },
    {
      id: 'CreatedAt', numeric: false, disablePadding: true, label: 'Created At',
      Component: ({ row }) => {
        return (
          dateDisplayComponent(row?.CreatedAt)
        )
      },
    }
  ];

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload, searchStatus])

  const fetchData = async () => {
    setIsLoading(true);
    let res = await dataService('get', 'TaskList', `IsMyTask=false&Status=${searchStatus}&searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
    setTaskList(res?.data);
    setTotalRecord(res?.totalRecord);
    setIsLoading(false);
  }

  const handleEdit = (row) => {

    history.push(`update-task/${row.TaskId}`)

  };

  const viewAction = (row) => {
    setViewDetailDialog({
      data :  row,
      extraData : row,
      isVisible : true
    })
  };

  const handleDelete = async (row) => {
    let res = await dataService('get', `TaskDelete/${row.TaskId}`);
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };

  const handleStatusChange = async (row, updateStatus) => {
    setIsLoading(true);
    let res = await dataService('post', `TaskStatusUpdate/${row.TaskId}`, { Status: updateStatus });
    setIsReload(!isReload);

  };

  let actionList = [
    { label: 'View', icon: VisibilityIcon, iconColor: 'purple-500', action: viewAction },
    { label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: handleEdit },
    { label: 'Delete', icon: DeleteIcon, iconColor: 'red-500', action: handleDelete, isConfirmAction: true }
  ]


  // remove edit action if not super admin
  if (currentUser()?.Role !== 'SUPER_ADMIN') {
    actionList = actionList.filter(ac => {
      return ac.label !== 'Edit'
    })
  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >

        <TaskTable
          view="task"
          title="Task List"
          columns={columns}
          rows={taskList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          // editAction={handleEdit}
          searchStatus={searchStatus}
          setSearchStatus={setSearchStatus}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
          setIsReload={setIsReload}
          handleStatusChange={handleStatusChange}
        />
      </Container>
      {viewDetailDialog?.isVisible &&
        <MyTaskDetailViewDialog
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setIsReload={setIsReload}
          isReload={isReload}
          open={viewDetailDialog}
          setOpen={setViewDetailDialog}
          onClose={() => {
            setViewDetailDialog({ data: null, isVisible: false, extraData: null })
          }} />}
      <ViewDataDialog open={open} onClose={setOpen} viewDialogData={viewDialogData} />
    </>
  );
};

export default TaskList;