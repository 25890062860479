import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AddCircleOutline, Edit } from '@mui/icons-material';
import { Box, Button, DialogActions, Grid, Link, useTheme } from '@mui/material';
import { CustomButton } from "components/Common/CustomButton";
import { dataService } from 'services/data-service';
import DeleteConfirmDialog from 'components/dialoges/DeleteConfirmDialog';
import moment from "moment";
import CreateCatalogueFileDialog from './CreateCatalogueFileDialog';
import { convertBlobToBase64 } from 'utils/helper';
import PDFViewerDialog from './PdfVIewerDialog';
import { LoadingIcon } from 'components/Icons/Loading';

function CatalogueFileListDialog(props) {
    const { onClose, open, title, setSelectedFile, selectedFile, isUpdate, selectedData, afterCall } = props;

    const theme = useTheme();

    const [catalogueFileData, setCatalogueFileData] = useState(isUpdate ? selectedData : [])
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = React.useState(false);
    const [confirmData, setConfirmData] = React.useState(null);

    const [isLoading, setIsLoading] = useState(false)
    const [openUploadDocumentsDialog, setOpenUploadDocumentsDialog] = useState({
        isVisible: false, data: null
    });
    const [pdfViewerOpen, setPdfViewerOpen] = useState({ isVisible: false, file: null, item : null })

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (!isUpdate) {
            fetchData()
        }
    }, [])


    const afterAPICall = async () => {
        if (isUpdate) {
            afterCall()
        }
        fetchData()
    }

    const handleConfirmClick = async (index, id) => {
        await dataService('post', `CatalogueFileDelete/${id}`, null);
        afterAPICall()
    }

    const fetchData = async () => {
        let res = await dataService('get', `CatalogueFileList/${selectedFile?.CatalogueId}`, null);
        if (res.status === 200) {
            if (res?.data) {
                setCatalogueFileData(res?.data)
            }

        }
    }

    const handleShowConfirmDialog = (id, index) => {
        setShowDeleteConfirmDialog(true)
        setConfirmData({ action: index, rowData: id })
    }

    const handleEditDialog = (file) => {
        setOpenUploadDocumentsDialog({
            data: file,
            isVisible: true
        })
    }


    const handleDialogCLose = () => {
        setOpenUploadDocumentsDialog({
            data: null, isVisible: false
        })
    }



    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={open}
            disableBackdropClick
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className={`border-2 flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className="pb-1 ">
                    <div className="sm:grid sm:grid-cols-2 items-center">
                        <div>

                            <div className='flex flex-row items-center gap-4'>
                                <span className="sm:text-2xl text-xl">{title}</span><br />
                                {selectedFile?.QRCodeURL &&
                                    <Button onClick={() => {
                                        window.open(selectedFile?.QRCodeURL, `_blank`)
                                    }} className='bg-blue-500 border-none py-0.5 px-3 shadow-none min-w-0 flex flex-wrap items-center mt-1 mr-4 text-white'>Preview
                                    </Button>}
                            </div>
                            <div className="text-lg text-gray-500 "> Title : <span style={{ color: '#f68220' }}>{selectedFile?.Title}</span></div>
                        </div>
                    </div>
                </DialogTitle>
                {catalogueFileData?.length !== 0 &&
                    <Button onClick={() => {
                        setOpenUploadDocumentsDialog({
                            data: null, isVisible: true
                        })
                    }} className='bg-blue-500 border-none py-0.5 px-3 shadow-none min-w-0 flex flex-wrap items-center :mt-1 xsm:mr-4 xsm:mb-0  mb-3 ml-5'>
                        <AddCircleOutline className='w-4 h-auto text-white' /> <span className='text-base text-white font-semibold ml-1'>Files</span>
                    </Button>}
            </div>
            <DialogContent>
                <Grid container>
                    {catalogueFileData?.length > 0 ?
                        <Grid item xs={12} lg={12}>
                            <div className="bg-indigo-300 border-indigo-300 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-3 sm:px-6">
                                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Catalogue</h3>
                                </div>
                                <div className="bg-white shadow overflow-hidden sm:rounded-lg ">
                                    <div className="border-t border-gray-200 p-5">
                                        {catalogueFileData?.map((file, index) => (
                                            <Grid container className='py-3 mt-1 border-b border-gray-200' key={index}>
                                                <Grid item xs={12} sm={8}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Link href={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${file?.FilePath}${file?.FileName}`} target="_blank" >
                                                                {/* {getFileIcon(file?.MimeType)} */}
                                                                <img width="40" className='rounded-lg' src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${file?.FilePath}${file?.FeaturedImage}`} alt={file?.FilePath}/>
                                                            </Link>
                                                        </Grid>
                                                        <Grid item>
                                                            <div className='text-lg font-semibold'>{file.Title}</div>
                                                            <p className='text-sm text-gray-400'>{moment(file.CreatedAt).format('D MMM, YYYY')} - {file.Size}</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={4} className="text-right sm:mt-0 mt-5">
                                                    <Box
                                                        aria-controls="simple-menu-1"
                                                        aria-haspopup="true"
                                                        onClick={(e) => {
                                                            handleEditDialog(file)
                                                        }}
                                                        sx={{ mb: 3.5, mr: 1 }}
                                                        size="small"
                                                        component={Button}
                                                        className='p-0'
                                                        width="2rem!important"
                                                        height="2rem!important"
                                                        minWidth="2rem!important"
                                                        minHeight="2rem!important"
                                                    >
                                                        <Edit fontSize="large" className='w-5 h-5' style={{ color: theme.palette.primary[500] }} />
                                                    </Box>
                                                    <Link href="#" onClick={() => handleShowConfirmDialog(file?.CatalogueFileId, index)} className="inline-block" >
                                                        <img width="40" alt='delete-icon' src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}assets/images/delete-icon.png`} />
                                                    </Link>
                                                    <div className="inline-block cursor-pointer" onClick={async () => {
                                                        setIsLoading(true)
                                                        try {
                                                            const response = await fetch(`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${file?.FilePath}${file?.FileName}`);
                                                            const blob = await response.blob();
                                                            const base64 = await convertBlobToBase64(blob);
                                                            setIsLoading(false)
                                                            setPdfViewerOpen({ file: base64, isVisible: true, item : file })
                                                        } catch (error) {
                                                            setIsLoading(false)
                                                            console.error('Error fetching and converting PDF:', error);
                                                        }
                                                    }}>
                                                        <img width="40" alt='download-cloud-icon' src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}assets/images/download-cloud-icon.png`} />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </div>
                                </div>
                            </div>


                        </Grid>
                        :
                        <Grid item xs={12} lg={12}>
                            <div className='flex flex-row items-center w-full justify-center'>
                                <Button onClick={() => {
                                    setOpenUploadDocumentsDialog({
                                        data: null, isVisible: true
                                    })
                                }} className='bg-blue-500 border-none py-2 px-3 shadow-none min-w-0 flex flex-wrap items-center mt-1 mr-4'>
                                    <AddCircleOutline className='w-4 h-auto text-white' /> <span className='text-base text-white font-semibold ml-1'>Files</span>
                                </Button>
                            </div>
                        </Grid>}
                    {showDeleteConfirmDialog &&
                        <DeleteConfirmDialog onClose={setShowDeleteConfirmDialog} open={showDeleteConfirmDialog} handleConfirm={handleConfirmClick} confirmData={confirmData} setConfirmData={setConfirmData} />
                    }
                </Grid>
            </DialogContent>
            <DialogActions className='border-t border-gray-200'>
                <CustomButton autoFocus className='bg-red-500 text-white border-none mx-1 text-base' size="none" label={`Cancel `} onClick={handleClose} iconClassName="w-5 h-auto" />
            </DialogActions>

            {openUploadDocumentsDialog.isVisible &&
                <CreateCatalogueFileDialog
                    onClose={handleDialogCLose}
                    open={openUploadDocumentsDialog}
                    selectedFile={selectedFile}
                    afterAPICall={afterAPICall}
                />
            }
            {
                pdfViewerOpen?.isVisible ? <PDFViewerDialog viewer={pdfViewerOpen} handleClose={() => {
                    setPdfViewerOpen({ file: null, isVisible: false })
                }} /> : null}

            {
                isLoading && <LoadingIcon overlay={isLoading} />
            }
        </Dialog>
    );
};
export default CatalogueFileListDialog;