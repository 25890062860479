import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';
import { Edit, Delete, Person, CalendarMonth } from "@mui/icons-material";
import CommonTable from "components/Tables/CommonTable";
import { Chip } from "@mui/material";
import { renderTitleDetails } from "utils/helper";
import place_dots from 'assets/img/place_dots.png';
import moment from "moment";


const useStyles = makeStyles(componentStyles);

const PreOrders = () => {
  const classes = useStyles();
  const [preOrderList, setPreOrderList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');

  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isLoading, setIsLoading] = useState(true);


  const [isReload, setIsReload] = useState(true);
  const columns = [
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer', dataType: 'string' },
    { id: 'CollectionName', numeric: false, disablePadding: false, label: 'Collection', dataType: 'string' },
    { id: 'Logo', numeric: false, disablePadding: false, label: 'Logo', dataType: 'string' },
    { id: 'Size', numeric: false, disablePadding: false, label: 'Size', dataType: 'string' },
    { id: 'Buckram', numeric: false, disablePadding: false, label: 'Buckram', dataType: 'string' },
    { id: 'SKU', numeric: false, disablePadding: false, label: 'SKU', dataType: 'string' },
    { id: 'MTR', numeric: false, disablePadding: false, label: 'MTR', dataType: 'string' },
    { id: 'Quantity', numeric: false, disablePadding: false, label: 'Quantity', dataType: 'string' },
    {
      id: 'CreatedAt', numeric: false, disablePadding: false, label: 'Create Detail', dataType: 'component',
      Component: ({ row }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CalendarMonth />
            <img src={place_dots} alt="" width={8} />
            <Person/>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
            {renderTitleDetails(`${moment(row?.CreatedAt).format("DD-MM-YYYY")}`, 25, { fontSize: 12, fontWeight: 500, textOverflow: 'ellipsis', whiteSpace: 'nowrap' })}
            <div style={{ height: '2px' }} />
            {renderTitleDetails(row?.CreatedByName || "Customer", 25, { fontSize: 12, fontWeight: 500, textOverflow: 'ellipsis', whiteSpace: 'nowrap' })}
          </Box>
        </Box>
      ),
    },
    {
      id: 'Status', numeric: false, disablePadding: false, label: 'Status', dataType: 'component',
      Component: ({ row }) => (
        <div>
          <Chip
            // className="font-bold !bg-blue-500"
            color={row?.Status === "Approved" ? "primary" : row?.Status === "Pending" ? "info" : "error"}
            variant="outlined"
            label={row?.Status}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let res = await dataService('get', 'PreOrderList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);

      if (res?.status === 200 || res?.status === 304) {
        setPreOrderList(res?.data);
        setTotalRecord(res?.totalRecord);
      }
      setIsLoading(false);
    }

    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload])

  const handleEdit = (row) => {
    history.push(`update-pre-order/${row.PreOrderId}`)
  };

  const handleDelete = async (row) => {
    let res = await dataService('post', `PreOrderDelete/${row.PreOrderId}`, null);
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };

  const tableData = () => {

    return <CommonTable
      title="Pre Orders"
      columns={columns}
      rows={preOrderList}
      page={page - 1}
      total={totalRecord}
      pagedata={setPage}
      setSearchText={setSearchText}
      searchText={searchText}
      order={order}
      orderBy={orderBy}
      orderData={setOrderData}
      orderByData={setOrderByData}
      setOffset={setOffset}
      offset={offset}
      loading={isLoading}
      view="Pre Order"
      actions={actionList}
      viewURL="pre-order"
    />
  }

  let actionList = [
    { label: 'Edit', icon: Edit, iconColor: 'blue-500', action: handleEdit },
    { label: 'Delete', icon: Delete, iconColor: 'red-500', action: handleDelete, isConfirmAction: true },
  ]

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        {tableData()}

      </Container>
    </>
  );
};

export default PreOrders;